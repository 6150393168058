import { Base } from '@studiometa/js-toolkit';
import type { BaseProps } from '@studiometa/js-toolkit';
import { gsap } from 'gsap';

export interface ButtonAnimatedProps extends BaseProps {
  $refs: {
    text: HTMLElement;
    inner2: HTMLElement;
    line: HTMLElement;
    top: HTMLElement;
    bottom: HTMLElement;
  };
  $options: {
    direction: string;
    length: number;
    position: string;
  };
}

/**
 * ButtonAnimated class.
 */
export class AnimatedButton extends Base<ButtonAnimatedProps> {

  tl: GSAPTimeline | null = null;
  /**
   * ButtonAnimated config
   */
  static config = {
    name: 'AnimatedButton',
    refs: ['text', 'inner2', 'line', 'top', 'bottom'],
    options: {
      direction: {
        type: String,
        default: 'right',
      },
      length: {
       type: Number,
       default: 50
      },
      position: {
        type: String,
        default: 'center'
      },
    },
  };

  /**
   * Mounted hook.
   */
  mounted(): void {
    // Create and store timeline.
    this.tl = gsap.timeline({paused: true});

    // Set up Tweens initial state.
    this.setTweens();

    // Creates the right timeline based on the direction property.
    if (this.$options.direction === 'right') {
      console.log('hallo')
      this.animateRight();
    } else if (this.$options.direction === 'left') {
      this.animateLeft();
    }

    // Binds a mouseenter listener to start/continue the animation.
    this.$el.addEventListener('mouseenter', () => {
      // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
      this.tl !== null && (this.tl.seek(0), this.tl.play());
    });

    // Binds a mouseleave listener to flyout or reverse the animation.
    this.$el.addEventListener('mouseleave', () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.tl !== null &&
      (this.tl.time() >= this.tl.labels.midpoint ? this.tl.play() : this.tl.reverse());
    });
  }

  /**
   * Sets up initial state of the animation objects.
   */
  setTweens(): void {
    // prettier-ignore
    gsap.set(this.$el, {
      willChange: 'transform',
    });
    if (this.$options.direction === 'left') {
      gsap.set(this.$refs.line, {
        width: 200,
        left: 500,
      });
      gsap.set([this.$refs.top, this.$refs.bottom], {
        opacity: 0,
        width: 1,
        left:
          this.$options.position === 'center'
            ? -this.$options.length / 2
            : -this.$options.length - 20,
      });
    } else {
      gsap.set(this.$refs.line, {
        width: 200,
        right: 500,
      });
      gsap.set([this.$refs.top, this.$refs.bottom], {
        opacity: 0,
        width: 1,
        right:
          this.$options.position === 'center'
            ? -this.$options.length / 2
            : -this.$options.length - 20,
      });
    }
    gsap.set(this.$refs.top, {rotationZ: '-45deg'});
    gsap.set(this.$refs.bottom, {rotationZ: '45deg'});
  }

  /**
   * Sets up timeline for to right animation.
   */
  animateRight() : void {
    // prettier-ignore
    const t = this.tl
      .to(this.$refs.text, {
        opacity: 0,
        left: 15,
        duration: 0.2,
        ease: 'sine.in',
      })
      .to(this.$refs.line, {
        right: this.$options.position === 'center'
          ? -this.$options.length / 2
          : -this.$options.length - 20,
        duration: 0.3,
        ease: 'expo.out',
      }, .1)
      .to(this.$refs.line, {
        width: this.$options.length,
        duration: 0.5,
        ease: 'expo.out',
      }, .3)
      .to([this.$refs.top, this.$refs.bottom], {
        opacity: 1,
        duration: 0.2,
        ease: 'sine.out',
      }, .35)
      .to([this.$refs.top, this.$refs.bottom], {
        width: 8,
        transformOrigin: '100% 50%',
        duration: 0.3,
        ease: 'power3.out',
      }, .45)
      // to right animation
      .addLabel('midpoint', .8)
      .add(() => {
        t.pause();
      }, 'midpoint')
      .set(this.$refs.text, {
        left: -15,
      }, 'midpoint0.31')
      .to([this.$refs.line, this.$refs.top, this.$refs.bottom], {
        right: -600,
        duration: 0.3,
        ease: 'expo.in',
      }, 'midpoint0.31')
      .to(this.$refs.text, {
        opacity: 1,
        duration: 0.3,
        ease: 'sine.out',
      }, 'midpoint0.5')
      .to(this.$refs.text, {
        left: 0,
        duration: 0.3,
        ease: 'sine.out',
      }, 'midpoint0.5');
    this.tl.pause();
  }

  /**
   * Sets up timeline for to left animation.
   */
  animateLeft() : void {
    // prettier-ignore
    const t = this.tl
      .to(this.$refs.text, {
        opacity: 0,
        right: 15,
        duration: 0.2,
        ease: 'sine.in',
      })
      .to(this.$refs.line, {
        left:
          this.$options.position === 'center'
            ? -this.$options.length / 2
            : -this.$options.length - 20,
        duration: 0.3,
        ease: 'expo.out',
      }, .1)
      .to(this.$refs.line, {
        width: this.$options.length,
        duration: 0.5,
        ease: 'expo.out',
      }, .3)
      .to([this.$refs.top, this.$refs.bottom], {
        opacity: 1,
        duration: 0.2,
        ease: 'sine.out',
      }, .35)
      .to([this.$refs.top, this.$refs.bottom], {
        width: 8,
        transformOrigin: '0% 50%',
        duration: 0.3,
        ease: 'power3.out',
      }, .45)
      // to right animation
      .addLabel('midpoint', .8)
      .add(() => {
        t.pause();
      }, 'midpoint')
      .set(this.$refs.text, {
        right: -15,
      }, 'midpoint0.31')
      .to([this.$refs.line, this.$refs.top, this.$refs.bottom], {
        left: -600,
        duration: 0.3,
        ease: 'expo.in',
      }, 'midpoint0.31')
      .to(this.$refs.text, {
        opacity: 1,
        duration: 0.3,
        ease: 'sine.out',
      }, 'midpoint0.5')
      .to(this.$refs.text, {
        right: 0,
        duration: 0.3,
        ease: 'sine.out',
      }, 'midpoint0.5');
    this.tl.pause();
  }

  /**
   * Destroy the component.
   */
  public destroyed() : void {
    this.setTweens();

    // eslint-disable-next-line unicorn/no-invalid-remove-event-listener
    this.$el.removeEventListener('mouseenter', () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.tl !== null && (this.tl.seek(0), this.tl.play());
    });

    // eslint-disable-next-line unicorn/no-invalid-remove-event-listener
    this.$el.removeEventListener('mouseleave', () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.tl !== null &&
      (this.tl.time() >= this.tl.labels.midpoint ? this.tl.play() : this.tl.reverse());
    });

    // Remove timeline
    this.tl.kill();
  }
}

import { Base, withMountWhenInView } from '@studiometa/js-toolkit';
import type { BaseConfig, BaseProps } from '@studiometa/js-toolkit';
import {gsap} from "gsap";

/**
 * Defines the properties interface for AnimatedLayer component.
 */
export interface AnimatedLayerProps extends BaseProps {
  $options: {
    start: String;
    delay: Number;
    ease: String;
    primaryDuration: Number;
    secondaryDuration: Number;
    secondaryOffset: Number;
  };
}

/**
 * Represents a hero component with animation capabilities.
 * @extends {Base<AnimatedLayerProps>}
 */
export default class AnimatedLayer extends withMountWhenInView<Base<AnimatedLayerProps>>(Base, {
  threshold: 0.5,
}) {
  static config: BaseConfig = {
    name: 'Layer',
    emits: ['layerload'],
    options: {
      start: {
        type: String,
        default: 'left'
      },
      delay: {
        type: Number,
        default: 0,
      },
      ease: {
        type: String,
        default: 'expo.inOut'
      },
      primaryDuration: {
        type: Number,
        default: 1.2
      },
      secondaryDuration: {
        type: Number,
        default: 1.2
      },
      secondaryOffset: {
        type: String,
        default: '-0.07'
      },
    },
  };

  /**
   * Retrieves an object based on the given starting position.
   * @param {string} start - The starting position.
   *    Possible values are 'left', 'right', 'top', or 'bottom'.
   * @return {Record<string, number>} - An object with four properties.
   *    The properties 'tl', 'tr', 'br', and 'bl' are all numbers.
   *    The values of the properties depend on the starting position.
   *    If start is 'left' or 'right':
   *      - 'tl' is 0, 'tr' is 100, 'br' is 100, 'bl' is 0.
   *    If start is 'top':
   *      - 'tl' is 100, 'tr' is 0, 'br' is 0, 'bl' is 100.
   *    If start is 'bottom':
   *      - 'tl' is 100, 'tr' is 0, 'br' is 100, 'bl' is 100.
   */
  private static getObj(start: string): Record<string, number> {
    switch(start) {
      case 'left': case 'right':
        return { tl: 0, tr: 100, br: 100, bl: 0 };
      case 'top':
        return { tl: 100, tr: 0, br: 0, bl: 100 };
      case 'bottom':
        return { tl: 100, tr: 0, br: 100, bl: 100 };
    }
  }

  /**
   * Returns an array containing the coordinates based on the provided start position and object values.
   * @param {string} start - The starting position. Valid values are 'left', 'right', 'top', 'bottom'.
   * @param {Record<string, number>} obj - The object containing the values for each property.
   * @returns {number[][]} - An array containing coordinate pairs.
   */
  private static getCoords(start: string, obj: Record<string, number>): number[][] {
    switch(start) {
      case 'left': case 'right':
        return [[obj.tl, 0], [obj.tr, 0], [obj.br, 100], [obj.bl, 100]];
      case 'top':
        return [[0, obj.tl], [0, obj.tr], [100, obj.br], [100, obj.bl]];
      case 'bottom':
        return [[0, obj.tl], [obj.tr, 0], [obj.br, 0], [100, obj.bl]];
    }
  }

  /**
   * Mounts the component by applying the clip path and emitting the 'layerload' event.
   * @this {AnimatedLayer & AnimatedLayerProps}
   */
  mounted(): void {
    this.applyClipPath();
    this.$emit('layerload');
  }

  /**
   * Applies a clip path animation to the element.
   * @this {AnimatedLayer & AnimatedLayerProps}
   */
  private applyClipPath(): void {
    let obj = AnimatedLayer.getObj(String(this.$options.start));
    let coords = AnimatedLayer.getCoords(String(this.$options.start), obj);

    const tl = gsap.timeline({
      pause: true,
      delay: Number(this.$options.delay) || 0,
      onUpdate: () => {
        if (this.$options.start === 'bottom') {
          coords[0][1] = obj.tl;
          coords[1][0] = obj.tr;
          coords[2][0] = obj.br;
          coords[3][1] = obj.bl;
          this.applyClip(this.$el, coords);
        } else if (this.$options.start === 'top') {
          coords[0][1] = obj.tl;
          coords[1][1] = obj.tr;
          coords[2][1] = obj.br;
          coords[3][1] = obj.bl;
          this.applyClip(this.$el, coords);
        } else if (this.$options.start === 'left' || this.$options.start === 'right') {
          coords[0][0] = obj.tl;
          coords[1][0] = obj.tr;
          coords[2][0] = obj.br;
          coords[3][0] = obj.bl;
          this.applyClip(this.$el, coords);
        }
      },
    });
    switch (this.$options.start) {
      case 'left':
        tl.to(obj, {
          tl: 100,
          tr: 100,
          duration: Number(this.$options.primaryDuration) || 0,
          ease: String(this.$options.ease)
        })
        tl.to(obj, {
          br: 100,
          bl: 100,
          duration: Number(this.$options.secondaryDuration) || 0,
          ease: String(this.$options.ease)
        }, String(this.$options.secondaryOffset));
        break;
      case 'right':
        tl.to(obj, {
          tl: 0,
          tr: 0,
          duration: Number(this.$options.primaryDuration),
          ease: String(this.$options.ease)
        });
        tl.to(obj, {
          br: 0,
          bl: 0,
          duration: Number(this.$options.secondaryDuration),
          ease: String(this.$options.ease)
        }, String(this.$options.secondaryOffset));
        break;
      case 'top':
        tl.to(obj, {
          tl: 100,
          tr: 100,
          duration: Number(this.$options.primaryDuration),
          ease: String(this.$options.ease)
        });
        tl.to(obj, {
          bl: 100,
          br: 100,
          duration: Number(this.$options.secondaryDuration),
          ease: String(this.$options.ease)
        }, String(this.$options.secondaryOffset));
        break;
      case 'bottom':
        tl.to(obj, {
          tl: 0,
          tr: 0,
          duration: Number(this.$options.primaryDuration),
          ease: String(this.$options.ease)
        });
        tl.to(obj, {
          br: 100,
          bl: 0,
          duration: Number(this.$options.secondaryDuration),
          ease: String(this.$options.ease)
        }, String(this.$options.secondaryOffset));
        break;
    }
    tl.play();
  }

  /**
   * Applies a clip path to the given element based on the provided coordinates.
   * @this {AnimatedLayer & AnimatedLayerProps}
   * @param {HTMLElement} element - The element to apply the clip path to.
   * @param {number[][]} coords - The coordinates of the clip path in the format [[x1, y1], [x2, y2], ...].
   */
  private applyClip(element: HTMLElement, coords: number[][]): void {
    const el = element;
    const a = [];
    coords.forEach((c) => {
      const [x, y] = c;
      a.push(`${x}% ${y}%`);
    });
    const str = `polygon(${a.join(',')})`;
    el.style.clipPath = str;
    el.style['webkitClipPath'] = str;
  }

  /**
   * Terminates the layer load process.
   * @this {AnimatedLayer & AnimatedLayerProps}
   */
  onLayerload(): void {
    this.$terminate();
  }
}

import {Base} from '@studiometa/js-toolkit';
import type {BaseProps, BaseConfig, BaseInterface, KeyServiceProps} from '@studiometa/js-toolkit';
import {addClass, removeClass} from '@studiometa/js-toolkit/utils';
import {NavigationMobile} from './NavigationMobile';

interface NavigationMobileToggleProps extends BaseProps {
  $parent: NavigationMobile;
  $el: HTMLElement;
  $refs: {
    trigger: HTMLElement;
  };
}

/**
 * @class NavigationMobileToggle
 * @classdesc Class for handling the mobile navigation toggle functionality.
 * @extends Base<NavigationMobileToggleProps>
 */
export class NavigationMobileToggle<T extends BaseProps = BaseProps>
  extends Base<T & NavigationMobileToggleProps>
  implements BaseInterface
{
  static config: BaseConfig = {
    name: 'NavigationMobileToggle',
    refs: ['trigger'],
  };

  /**
   * Internal state to track if the menu is open.
   * @private
   */
  private isOpen = false;

  /**
   * Toggle the menu's open and closed states on trigger click.
   * Invokes {@link open} or {@link close} based on the current state.
   * @this {NavigationMobileToggle & NavigationMobileToggleProps}
   * @private
   */
  private onTriggerClick(): void {
    const newState = !this.isOpen ? 'open' : 'close';
    this[newState]();

    // Emit a custom event with the new state
    this.$emit(`Toggle.mobileMenu.${newState}`);
  }

  /**
   * Open the navigation menu.
   * Adds necessary CSS classes to elements and updates the state.
   * @this {NavigationMobileToggle & NavigationMobileToggleProps}
   * @private
   */
  private open(): void {
    addClass(document.documentElement, 'scroll-locked');
    addClass(this.$parent.$el, 'is-open');
    this.isOpen = true;
  }

  /**
   * Close the navigation menu.
   * Removes CSS classes from elements and updates the state.
   * @this {NavigationMobileToggle & NavigationMobileToggleProps}
   * @private
   */
  private close(): void {
    removeClass(document.documentElement, 'scroll-locked');
    removeClass(this.$parent.$el, 'is-open');
    this.isOpen = false;
  }

  /**
   * Manage keyboard input for the component.
   * Allows closing the menu using the ESC key.
   * @this {NavigationMobileToggle & NavigationMobileToggleProps}
   * @param {KeyServiceProps} param0 - Object containing key states.
   * @public
   */
  public keyed({ ESC }: KeyServiceProps) {
    if (ESC) {
      this.$emit('Toggle.mobileMenu.close');
    }
  }

  /**
   * Handle component destruction and cleanup.
   * Ensures the navigation menu is closed when the component is destroyed.
   * @this {NavigationMobileToggle & NavigationMobileToggleProps}
   * @public
   */
  public destroyed(): void {
    this.close();
  }
}

import {Base} from '@studiometa/js-toolkit';
import type {BaseProps, BaseConfig} from '@studiometa/js-toolkit';
import {gsap} from 'gsap';

interface NavigationContextualDesktopProps extends BaseProps {
  $el: HTMLElement;
  $refs: {
    line: HTMLElement;
    links: HTMLElement[];
    titles: HTMLElement[];
  };
}

/**
 * Represents a navigation dropdown menu.
 * @extends {Base<NavigationContextualDesktopProps>}
 */
export class NavigationContextualDesktop extends Base<NavigationContextualDesktopProps> {
  /**
   * Component config.
   */
  static config: BaseConfig = {
    name: 'NavigationContextualDesktop',
    refs: ['line', 'links[]', 'titles[]'],
  };

  /**
   * The `timeline` variable is an instance of the `gsap.core.Timeline` class or `null`.
   * It is used to create and manage animations using the GSAP animation library.
   *
   * @type {gsap.core.Timeline|null}
   */
  timeline: gsap.core.Timeline | null;

  /**
   * Attaches event listeners to each link element and sets initial line position.
   * @this {NavigationContextualDesktop & NavigationContextualDesktopProps}
   */
  public mounted(): void {
    this.$refs.links.forEach((link) => {
      link.addEventListener('mouseover', this.onMouseOver);
      link.addEventListener('mouseout', this.onMouseOut);
    });

    // Set initial line position under the first item
    this.setInitialLinePosition(this.$refs.line, this.$refs.links[0]);

    // Check if there's an active element
    const activeElement = this.getActiveElement(this.$refs.links);
    if (activeElement) {
      this.setInitialLinePosition(this.$refs.line, activeElement);
    } else {
      this.$refs.line.style.display = 'none';
    }
  }

  /**
   * onMouseOver function.
   * @this {NavigationContextualDesktop & NavigationContextualDesktopProps}
   */
  private onMouseOver = (event: Event): void => {
    this.animateLine(this.$refs.line, event.currentTarget as HTMLElement);
    this.$refs.line.style.display = 'block';
  };

  /**
   * Handles the mouse out event.
   * @this {NavigationContextualDesktop & NavigationContextualDesktopProps}
   */
  private onMouseOut = (): void => {
    const activeElement = this.getActiveElement(this.$refs.links);
    if (activeElement) {
      this.animateLine(this.$refs.line, activeElement);
    } else {
      this.$refs.line.style.display = 'none';
    }
  };

  /**
   * Handles window resize events.
   * @this {NavigationContextualDesktop & NavigationContextualDesktopProps}
   */
  private resized (): void {
    const activeElement = this.getActiveElement(this.$refs.links);
    if (activeElement) {
      this.animateLine(this.$refs.line, activeElement);
    } else {
      this.$refs.line.style.display = 'none';
    }
  };

  /**
   * Returns the active element from the given array of HTMLElements.
   * @this {NavigationContextualDesktop & NavigationContextualDesktopProps}
   * @param {HTMLElement[]} elements - The array of HTMLElements to search in.
   * @returns {HTMLElement} - The active element, or the first element if no active elements found.
   */
  private getActiveElement(elements: HTMLElement[]): HTMLElement {
    return elements.find((el) => el.classList.contains('active'));
  }

  /**
   * Sets the initial position and size of the line under the target element.
   * @this {NavigationContextualDesktop & NavigationContextualDesktopProps}
   * @param {HTMLElement} line - The line element to position.
   * @param {HTMLElement} target - The target element under which to position the line.
   */
  private setInitialLinePosition(line: HTMLElement, target: HTMLElement): void {
    const rect = target.getBoundingClientRect();
    line.style.left = `${rect.left + window.scrollX}px`;
    line.style.top = `${target.offsetTop + target.offsetHeight}px`;
    line.style.width = `${rect.width}px`;
    line.style.height = '4px'; // Assuming a constant height for the line
    line.style.display = 'block'; // Make sure the line is visible
  }

  /**
   * Animates a line to a target element.
   * @this {NavigationContextualDesktop & NavigationContextualDesktopProps}
   * @param {HTMLElement} line - The line element to animate.
   * @param {HTMLElement} target - The target element to animate the line to.
   */
  private animateLine(line: HTMLElement, target: HTMLElement): void {
    // Guard clause to check if line or target are not defined
    if (!line || !target) {
      console.error('Target or line elements are undefined in animateLine()');
      return;
    }

    const rect = target.getBoundingClientRect();
    const lineLeft = rect.left + window.scrollX;
    const lineTop = target.offsetTop + target.offsetHeight;
    const lineWidth = rect.width;

    this.timeline = gsap.timeline();
    this.timeline
      .to(line, {
        duration: 0.6,
        left: `${lineLeft}px`,
        ease: 'back.out(2.8)',
      })
      .to(line, {
        duration: 0.5,
        top: `${lineTop}px`,
        width: `${lineWidth}px`,
        height: '4px',
      }, "-=0.6");
  }

  /**
   * Removes event listeners from all links and the window resize event.
   * @this {NavigationContextualDesktop & NavigationContextualDesktopProps}
   */
  public destroyed(): void {
    this.$refs.line.style.display = 'none';
    this.$refs.links.forEach((link) => {
      link.removeEventListener('mouseover', this.onMouseOver);
      link.removeEventListener('mouseout', this.onMouseOut);
    });
  }
}

/**
 * Helper to get DOM elements in the context of a specific element.
 * If no context is provided, the document will be taken as context.
 *
 * @param selector {string}
 * @param {HTMLElement | Document} context
 * @returns {NodeList}
 */
export const queryAll = (selector: string, context: HTMLElement | Document = document) =>
  [].slice.call(context.querySelectorAll(selector));

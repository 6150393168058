/**
 * Helper to query a single HTMLElement in a given context.
 * If no context is provided, the document will be taken as context.
 *
 * @param selector {string}
 * @param {HTMLElement | Document} context
 * @returns {HTMLElement}
 */
export const queryOne = (selector: string, context: HTMLElement | Document = document) =>
  context.querySelector(selector);

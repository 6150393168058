import { Base } from '@studiometa/js-toolkit';
import type { BaseProps, BaseConfig } from '@studiometa/js-toolkit';
import { keyCodes } from '@studiometa/js-toolkit/utils';
import { gsap } from 'gsap';
import { Curtain } from '../../atoms';
import { AnimatedButton } from '../../atoms';
import { queryOne, queryAll, clipRect } from '../../utils';

interface NavigationDropdownProps extends BaseProps {
  $el: HTMLElement;
  $options: {
    isOpen: boolean;
    isAnimate: boolean;
  };
  $refs: {
    dropdownContainer: HTMLElement;
    panel: HTMLElement;
    level: HTMLElement | HTMLElement[];
    card: HTMLElement;
    content: HTMLElement;
    cover: HTMLElement;
    links: HTMLElement[];
  };
}

/**
 * @class NavigationDropdown
 * @classdesc Represents a navigation dropdown menu.
 * @extends {Base<NavigationDropdownProps>}
 */
export class NavigationDropdown extends Base<NavigationDropdownProps> {
  /**
   * Component config.
   */
  static config: BaseConfig = {
    name: 'NavigationDropdown',
    refs: [
      'dropdownContainer',
      'panel',
      'card',
      'content',
      'cover',
      'links[]'
    ],
    emits: ['curtainAction'],
    components: {AnimatedButton},
    options: {
      empty: Boolean,
      allowDoubleClick: Boolean,
    },
  };

  /**
   * Reference to the Curtain component. Used to control the backdrop's visibility.
   * @private
   */
  private curtain: Curtain | null = null;

  /**
   * Indicates whether the dropdown is empty.
   * @public
   */
  public isEmpty: boolean;

  /**
   * Indicates whether the dropdown is currently open.
   * @private
   */
  private isOpen: boolean;

  /**
   * Indicates whether an animation is currently in progress within the dropdown.
   * @private
   */
  private isAnimate: boolean;

  /**
   * An array of HTMLElements representing the links within the dropdown.
   * @private
   */
  private links: HTMLElement[];

  /**
   * The currently active level in the dropdown menu. Can be a single element or an array of elements.
   * @private
   */
  private level: any;

  /**
   * Mounted hook.
   * Initializes the component, sets options and booleans and binds event listeners.
   * See {@link bindListeners} for more details on event binding.
   * @this {NavigationDropdown & NavigationDropdownProps}
   * @public
   */
  public mounted(): void {
    this.links =  queryAll('li[data-nav-level="0"] > a[data-ref="links[]"', this.$refs.panel as HTMLElement);

    // Options
    this.$options.allowDoubleClick = true;

    // Booleans
    this.isEmpty = this.links.length < 0;
    this.isOpen = false;
    this.isAnimate = false;

    // Check for dropdown links.
    if (!this.isEmpty) {
      this.bindListeners();
    }
  }

  /**
   * Binds necessary event listeners for dropdown functionality.
   * Invoked in the {@link mounted} lifecycle method.
   * @this {NavigationDropdown & NavigationDropdownProps}
   * @private
   */
  private bindListeners(this: NavigationDropdown & NavigationDropdownProps): void {
    // Handles opening and switching dropdown menus.
    this.links?.forEach((link) => {
      link.addEventListener('click', this.clicked);
    });

    // Handles auto close when clicking outside of the container.
    document.addEventListener('click', this.clickOutside);

    // Handles auto close when pressing escape and the dropdown is opened.
    document.addEventListener('keydown', this.clickOutside);
  }

  /**
   * Removes event listeners when the component is destroyed.
   * Counterpart to {@link bindListeners}.
   * @this {NavigationDropdown & NavigationDropdownProps}
   * @private
   */
  private unbindListeners(): void {
    this.links?.forEach((link) => {
      link.removeEventListener('click', this.clicked);
    });

    document.removeEventListener('click', this.clickOutside);
    document.removeEventListener('keyup', this.clickOutside);
  }

  /**
   * Handles click events on dropdown links. Initiates the opening of a dropdown level.
   * See {@link openLevel} and {@link closeDropdown} for related actions.
   * @param {Event} e - The event object.
   * @this {NavigationDropdown & NavigationDropdownProps}
   * @private
   */
  private clicked = (e: Event): void => {
    const target = e.currentTarget as HTMLElement;
    const li = target.parentNode as HTMLElement;
    const level = queryOne('[data-nav-level="1"]', li) as HTMLElement;

    if (level && !level.classList.contains('is-open')) {
      e.preventDefault();
      this.openLevel(level);
      this.$update();
    } else if (this.$options.allowDoubleClick) {
      e.preventDefault();
      this.closeDropdown();
      this.closeAll();
    }
  }

  /**
   * Closes the dropdown menu if a click occurs outside it.
   * Relies on the logic defined in {@link closeAll} and {@link closeDropdown}.
   * @param {Event} e - The event object.
   * @this {NavigationDropdown & NavigationDropdownProps}
   * @private
   */
  private clickOutside = (e: Event): void => {
    if (!this.isOpen) {
      return;
    }

    let el = e.target as HTMLElement;
    const isNav = (el: HTMLElement) =>
      el &&
      el.classList &&
      (el.classList.contains('has-dropdown') || el.hasAttribute('data-nav-ref'));

    while (el && !isNav(el)) {
      el = el.parentNode as HTMLElement;
    }

    if (!isNav(el) || (e as KeyboardEvent).keyCode === keyCodes.ESC) {
      this.closeAll();
      this.closeDropdown();
    }
  }

  /**
   * Closes all open dropdown menus. Invoked in various interaction scenarios.
   * Works in conjunction with {@link closeLevel} for comprehensive closure.
   * @this {NavigationDropdown & NavigationDropdownProps}
   * @private
   */
  private closeAll(): void {
    const siblings = queryAll('[data-ref="containers[]"][data-nav-level="1"]', this.$el);
    siblings.forEach((s) => {
      this.closeLevel(s);
    });
  }

  /**
   * Removes CSS classes from an open dropdown menu.
   * Used by {@link closeAll} to reset the state of dropdown levels.
   * @param {HTMLElement} level - The dropdown level element.
   * @this {NavigationDropdown & NavigationDropdownProps}
   */
  private closeLevel(level: HTMLElement): void {
    this.$el.classList.remove('active');
    level.classList.remove('is-open');
    level.previousElementSibling?.classList.remove('has-open');
  }

  /**
   * Opens a specified level of the dropdown.
   * Used in {@link clicked} to display the appropriate dropdown content.
   * @param {HTMLElement} level - The dropdown level to open.
   * @this {NavigationDropdown & NavigationDropdownProps}
   * @private
   */
  private openLevel(level: HTMLElement): void {
    this.closeAll();

    this.$el.classList.add('active');
    level.classList.add('is-open');
    level.previousElementSibling?.classList.add('has-open');

    const cloneLevel = level.cloneNode(true) as HTMLElement;
    cloneLevel.style.display = 'block';

    this.level = cloneLevel;

    if (!this.isAnimate) {
      if (this.isOpen) {
        this.updateDropdown(cloneLevel);
      } else {
        this.openDropdown(cloneLevel);
      }
    }
  }

  /**
   * Triggers the initial opening animation of the dropdown.
   * Called within {@link openLevel} when a new dropdown level is opened.
   * @param {HTMLElement} level - The dropdown level element.
   * @this {NavigationDropdown & NavigationDropdownProps}
   * @private
   */
  private openDropdown(level: HTMLElement): void {
    this.isOpen = true;
    this.isAnimate = true;
    this.$refs.dropdownContainer?.appendChild(level);

    // Content is in absolute position
    // so we need to grab height
    const h = level.offsetHeight;
    this.$refs.dropdownContainer.style.zIndex = '-1';
    this.$refs.dropdownContainer.style.transform = `translateY(-${h + 20}px)`;
    this.$refs.dropdownContainer.style.height = `${h}px`;

    const dropdownContent = queryOne('[data-nav-level="1"]', this.$refs.dropdownContainer) as HTMLElement;
    const nav = queryOne('nav[data-nav-level="1"]', dropdownContent) as HTMLElement;
    const card = queryOne('[data-ref="card"]', this.$refs.dropdownContainer) as HTMLElement;

    const openDropdownTL = gsap.timeline({
      paused: true,
      onComplete: () => {
        this.isAnimate = false;
      },
    });

    // prettier-ignore
    openDropdownTL.call(() => {
      document.dispatchEvent(new CustomEvent('curtainAction', { detail: { action: 'show'} }));
    }, []);
    openDropdownTL
      .to(this.$refs.dropdownContainer, {
          y: 0,
          duration: 0.8,
          ease: 'circ.inOut',
        }, '-=0.05',
      )
      .from(card, {
        opacity: 0,
        duration: 0.2,
      })
      .from(nav, {
          opacity: 0,
          duration: 0.2,
        }, '-=0.15',
      );

    openDropdownTL.play();
  }

  /**
   * Updates and animates the dropdown content when switching between different levels.
   * This method is a key part of the dynamic content update in the dropdown.
   * @param {HTMLElement} level - The new dropdown level element.
   * @this {NavigationDropdown & NavigationDropdownProps}
   * @private
   */
  updateDropdown(level: HTMLElement): void {
    this.isAnimate = true;
    const old = queryOne('[data-nav-level="1"]', this.$refs.dropdownContainer) as HTMLElement;
    const oldNav = queryOne('nav[data-nav-level="1"]', old) as HTMLElement;
    const oldCard = queryOne('[data-ref="card"]', old) as HTMLElement;

    const lvlNav = queryOne('nav[data-nav-level="1"]', level) as HTMLElement;
    const lvlCard = queryOne('[data-ref="card"]', level) as HTMLElement;

    level.style.opacity = String(0);
    level.style.position = 'absolute';
    lvlNav.style.opacity = String(0);

    this.$refs.dropdownContainer.appendChild(level);
    const h = level.offsetHeight;
    const updateDropdownTL = gsap.timeline();

    // Links animation (fadeOut)
    updateDropdownTL.to(oldNav, {
      opacity: 0,
      duration: 0.25,
      ease: 'power1.in',
    });

    // (clip)
    if (oldCard) {
      /**
       * @todo {Refactor} animate scaleX instead of right, bottom, left, top
       * @todo {Refactor} clean up code after moving to sacleX, much of the calculations will drop
       */
      oldCard.style.height = `${oldCard.offsetHeight}px`;

      const oldImg = queryOne('[data-ref="cover"] figure', old) as HTMLElement;
      const oldImgWidth = oldImg.offsetWidth;
      const oldImgHeight = oldImg.offsetHeight;
      const oldImgClip = {
        top: 0,
        right: oldImgWidth,
        bottom: oldImgHeight,
        left: 0,
      };

      const oldBlock = queryOne('[data-ref="content"]', old) as HTMLElement;
      const oldBlockHeight = oldBlock.offsetHeight;
      const oldBlockWidth = oldBlock.offsetWidth;
      const oldBlockMargin = window
        .getComputedStyle(oldBlock, null)
        .getPropertyValue('margin-left');
      const oldBlockClip = {
        top: 0,
        right: oldBlockWidth,
        bottom: oldBlockHeight,
        left: 0,
      };
      oldBlock.style.left = oldBlockMargin;
      oldBlock.style.position = 'absolute';

      // prettier-ignore
      updateDropdownTL.add(
        gsap.to(oldImgClip, {
          left: oldImgWidth,
          duration: 0.45,
          ease: 'power2.in',
          onUpdate() {
            oldImg.style.clip = clipRect(oldImgClip);
          },
        }), '0');

      // prettier-ignore
      updateDropdownTL.add(
        gsap.to(oldBlockClip, {
          left: oldBlockWidth,
          duration: 0.45,
          ease: 'power2.in',
          onUpdate() {
            oldBlock.style.clip = clipRect(oldBlockClip);
          },
        }), '+0.05');
    }

    // dropdown menu animation (height)
    // prettier-ignore
    updateDropdownTL.add(
      gsap.to(this.$refs.dropdownContainer, {
        height: h,
        duration: 0.2,
        ease: 'power1.inOut',
      }), '-=0.025');

    // card animation (clip)
    if (lvlCard) {
      /**
       * @todo {Refactor} animate scaleX instead of right, bottom, left, top
       * @todo {Refactor} clean up code after moving to sacleX, much of the calculations will drop
       */
      lvlCard.style.height = `${lvlCard.offsetHeight}px`;

      const lvlImg = queryOne('[data-ref="cover"] figure', level) as HTMLElement;
      const lvlImgWidth = lvlImg.offsetWidth;
      const lvlImgHeight = lvlImg.offsetHeight;
      const lvlImgClip = {
        top: 0,
        right: 0,
        bottom: lvlImgHeight,
        left: 0,
      };
      lvlImg.style.clip = clipRect(lvlImgClip);

      const lvlBlock = queryOne('[data-ref="content"]', level) as HTMLElement;
      const lvlBlockHeight = lvlBlock.offsetHeight;
      const lvlBlockWidth = lvlBlock.offsetWidth;
      const lvlBlockMargin = window
        .getComputedStyle(lvlBlock, null)
        .getPropertyValue('margin-left');
      const lvlBlockClip = {
        top: 0,
        right: 0,
        bottom: lvlBlockHeight,
        left: 0,
      };
      lvlBlock.style.left = lvlBlockMargin;
      lvlBlock.style.position = 'absolute';
      lvlBlock.style.clip = clipRect(lvlBlockClip);

      // prettier-ignore
      updateDropdownTL.add(
        gsap.to(lvlImgClip, {
          right: lvlImgWidth,
          duration: 0.55,
          ease: 'power1.out',
          onUpdate() {
            lvlImg.style.clip = clipRect(lvlImgClip);
          },
        }), '-=0.05');

      // prettier-ignore
      updateDropdownTL.add(
        gsap.to(lvlBlockClip, {
          right: lvlBlockWidth,
          duration: 0.55,
          ease: 'power1.out',
          onUpdate() {
            lvlBlock.style.clip = clipRect(lvlBlockClip);
          },
        }), '-=0.1');
    }

    // eslint-disable-next-line no-param-reassign
    level.style.opacity = String(1);

    // prettier-ignore
    updateDropdownTL
      .add(gsap.to(lvlNav, {
        opacity: 1,
        duration: 0.2,
        ease: 'power1.out',
      }), '-=0.15')
      .then(() => {
        this.$refs.dropdownContainer.removeChild(old);
        if (lvlCard) {
          const lvlImg = queryOne('[data-ref="cover"] figure', level) as HTMLElement;
          const lvlBlock = queryOne('[data-ref="content"]', level) as HTMLElement;

          // Clear lvlImg
          lvlImg.style.clip = '';

          // Clear lvlBlock
          lvlBlock.style.clip = '';
          lvlBlock.style.left = '';
          lvlBlock.style.position = 'relative';
        }
        this.isAnimate = false;
      });
  }

  /**
   * Animates the dropdown container when closing the menu.
   * It's the closing counterpart of {@link openDropdown}.
   * @this {NavigationDropdown & NavigationDropdownProps}
   * @private
   */
  private closeDropdown(): void {
    this.isAnimate = true;
    const closeDropdownTL = gsap.timeline({
      paused: true,
      onComplete: () => {
        this.clearDropdown();
        this.isOpen = false;
        this.isAnimate = false;
        gsap.set([this.$refs.dropdownContainer], {
          clearProps: 'all',
        });
        document.dispatchEvent(new CustomEvent('curtainAction', {detail: {action: 'clear'}}));
      },
    });

    // prettier-ignore
    closeDropdownTL
      .to(this.$refs.dropdownContainer, {
        autoAlpha: 0,
        duration: 0.2,
        ease: 'power2.inOut',
      })
    closeDropdownTL.call(() => {
      document.dispatchEvent(new CustomEvent('curtainAction', {
        detail: {
          action: 'hide',
          animation: {
            duration: 0.2
          }
        } }));
      }, [], -0.1);

    closeDropdownTL.play();
  }

  /**
   * Adjusts dropdown height on window resize to maintain layout integrity.
   * Complements the dynamic content sizing in {@link openDropdown} and {@link updateDropdown}.
   * @this {NavigationDropdown & NavigationDropdownProps}
   * @private
   */
  private resized(): void {
    if (this.isOpen && this.$refs.dropdownContainer instanceof HTMLElement) {
      const levelHeight = this.level.offsetHeight;
      this.level.style.height = 'auto';
      this.level.style.height = `${levelHeight}px`;
      this.$refs.dropdownContainer.style.height = `${levelHeight}px`;
    }
  }

  /**
   * Clears the dropdown container and resets its height to zero.
   * Typically called as part of the closure process in {@link closeDropdown}.
   * @this {NavigationDropdown & NavigationDropdownProps}
   * @private
   */
  private clearDropdown(): void {
    if (this.$refs.dropdownContainer && this.$refs.dropdownContainer instanceof HTMLElement) {
      this.$refs.dropdownContainer.innerHTML = '';
      this.$refs.dropdownContainer.style.height = String(0);
    }
  }

  /**
   * Cleans up the component when it is destroyed, typically when switching to a mobile variant.
   * Ensures removal of event listeners and resets the component's state.
   * @public
   */
  public destroyed(): void {
    this.isOpen = false;
    this.unbindListeners();
    this.clearDropdown();

    // Clearing is-open class
    const siblings = queryAll('[data-ref="containers[]"][data-nav-level="1"]', this.$el);
    siblings.forEach((s) => {
      this.closeLevel(s);
    });

    // Reset gsap styles
    gsap.set([this.$refs.links, this.$refs.dropdownContainer], {
      clearProps: 'all',
    });

    document.dispatchEvent(new CustomEvent('curtainAction', {detail: {action: 'clear'}}));

    //this.$refs.dropdownContainer = null;
  }
}

import { withMountWhenInView } from '@studiometa/js-toolkit';
import type { BaseConfig, BaseProps } from '@studiometa/js-toolkit';
import type Gallery from "../gallery/Gallery";
import { Transition } from '../../primitives/index.js';

export interface FigureImageProps extends BaseProps {
  $children: {
    Gallery: Promise<Gallery>[];
  }
  $refs: {
    img: HTMLImageElement;
    sources: HTMLSourceElement[];
  };
  $options: {
    lazy: boolean;
  };
}

/**
 * FigureImage class.
 */
export class FigureImage<T extends BaseProps = BaseProps> extends withMountWhenInView<Transition>(
  Transition,
  {
    threshold: [0, 1],
  },
)<T & FigureImageProps> {
  /**
   * Config.
   */
  static config: BaseConfig = {
    ...Transition.config,
    name: 'FigureImage',
    emits: ['load'],
    components: {
      Gallery: () => import('../../molecules/gallery/Gallery.js'),
    },
    refs: ['img', 'sources[]'],
    options: {
      ...Transition.config.options,
      lazy: Boolean,
    },
  };

  /**
   * Get the transition target.
   * @this {FigureImage & FigureImageProps}
   * @return {HTMLElement} The target element of the transition.
   */
  get target() {
    return this.$refs.img;
  }

  /**
   * Get the image source.
   * @this {FigureImage & FigureImageProps}
   * @return {string} The URL of the image source.
   */
  get src() {
    return this.$refs.img.src;
  }

  /**
   * Sets the image source.
   * @this {FigureImage & FigureImageProps} - The context of the method execution.
   * @param {string} value - The new source value for the image.
   */
  set src(value: string) {
    this.$refs.img.src = value;
  }

  /**
   * Get the image source set.
   * @this {FigureImage & FigureImageProps}
   * @return {string} The image source set.
   */
  get srcset() {
    return this.$refs.img.srcset;
  }

  /**
   * Set the image source set.
   * @this {FigureImage & FigureImageProps}
   * @param {string} value - The source set string to be set.
   */
  set srcset(value: string) {
    this.$refs.img.srcset = value;
  }

  /**
   * Get the original source of the image.
   * @this {FigureImage & FigureImageProps}
   * @return {string} The original source URL of the image.
   */
  get original(): string {
    return this.$refs.img.dataset.src;
  }

  /**
   * Get the original source set.
   * @this {FigureImage & FigureImageProps}
   * @return {string} The original source set.
   */
  get originalSrcset(): string {
    return this.$refs.img.dataset.srcset;
  }

  /**
   * Get the original sources.
   * @this {FigureImage & FigureImageProps}
   * @returns {Array<string>} An array of original sources.
   */
  get originalSources(): string[] {
    return this.$refs.sources.map((s) => s.dataset.srcset);
  }

  /**
   * Checks whether the browser supports the AVIF or WebP image format.
   * If the browser supports the format, the `src` and `srcset` attributes of the `<img>` element
   * and each `<source>` element are updated with the original AVIF or WebP source.
   * If the browser does not support the format, the `replaceWebpAvifWithJpg()` method is called.
   * @this {FigureImage & FigureImageProps}
   * @return {void}
   */
  private checkWebpAvifSupport(): void {
    const { img, sources } = this.$refs;

    const hasAvifExtension = (url: string) => /\.avif$/.test(url);

    let avifTestDone = false;

    if (img.dataset.src && hasAvifExtension(img.dataset.src)) {
      const avifImage = new Image();
      avifImage.src = img.dataset.src;
      avifImage.onerror = async () => {
        this.replaceAvifWithOriginal();
      };
      avifTestDone = true;
    }

    if (!avifTestDone) {
      sources.forEach((source) => {
        if (source.dataset.srcset && hasAvifExtension(source.dataset.srcset)) {
          const avifImage = new Image();
          avifImage.srcset = source.dataset.srcset;
          avifImage.onerror = async () => {
            this.replaceAvifWithOriginal();
          };
        }
      });
    }
  }

  /**
   * Replaces the `.avif` and `.webp` file extensions in the `data-src` and `data-srcset`
   * attributes of the `<img>` element and each `<source>` element within the `FigureImage` class
   * with the value of the `data-type` attribute.
   * @this {FigureImage & FigureImageProps}
   * @return {void}
   */
  private replaceAvifWithOriginal(): void {
    const { img, sources } = this.$refs;

    if (img.dataset.src) {
      img.src = img.dataset.src;
      delete img.dataset.src;
    }

    if (img.dataset.srcset) {
      img.srcset = img.dataset.srcset;
      delete img.dataset.srcset;
    }

    sources.forEach((source) => {
      if (source.dataset.srcset) {
        source.srcset = source.dataset.srcset;
        delete source.dataset.srcset;
      }
    });
  }

  /**
   * Load on mount.
   * @this {FigureImage & FigureImageProps}
   */
  mounted() {
    const {img, sources} = this.$refs;

    if (!img) {
      throw new Error('[FigureImage] The `img` ref is required.');
    }

    if (!(img instanceof HTMLImageElement)) {
      throw new Error('[FigureImage] The `img` ref must be an `<img>` element.');
    }

    const src = this.original;
    const srcset = this.originalSrcset;

    if (this.$options.lazy && src && src !== this.src) {
      this.checkWebpAvifSupport();
      let tempImg = new Image();
      tempImg.addEventListener(
        'load',
        async () => {
          this.src = src;
          this.srcset = srcset;
          for (let i = 0; i < sources.length; i++) {
            sources[i].srcset = this.originalSources[i];
            delete sources[i].dataset.srcset;
          }
          delete img.dataset.src;
          delete img.dataset.srcset;
          tempImg = null;
          this.enter();
          this.$emit('load');
        },
        { once: true },
      );
      tempImg.src = src;
    }
  }

  /**
   Terminate the component on load.
   */
  onLoad() {
    this.$terminate();
  }
}

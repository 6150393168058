import { Base, withBreakpointManager } from '@studiometa/js-toolkit';
import type { BaseProps } from '@studiometa/js-toolkit';
import { NavigationMobile } from './NavigationMobile';
import { NavigationDesktop } from './NavigationDesktop';

type NavigationProps = BaseProps;

/**
 * @class Navigation
 * @classdesc Manages the overall navigation logic, switching between mobile and desktop navigation based on breakpoints.
 * @extends {withBreakpointManager(Base, [
 *   ['sm md lg', NavigationMobile],
 *   ['xl nav 2xl', NavigationDesktop],
 * ])<NavigationProps>}
 */
export class Navigation extends withBreakpointManager(Base, [
  ['sm md lg', NavigationMobile],
  ['xl 2xl nav mega', NavigationDesktop],
])<NavigationProps> {
  static config = {
    name: 'Navigation',
  };
}

import { Base } from '@studiometa/js-toolkit';
import type { BaseProps, BaseConfig } from '@studiometa/js-toolkit';
import { NavigationDropdown } from './NavigationDropdown';

interface NavigationDesktopProps extends BaseProps {
  $el: HTMLElement;
}

/**
 * @class NavigationDesktop
 * @classdesc Manages the desktop version of the navigation.
 * @extends {Base<NavigationDesktopProps>}
 */
export class NavigationDesktop extends Base<NavigationDesktopProps> {
  static config: BaseConfig = {
    name: 'NavigationDesktop',
    components: {NavigationDropdown},
    emits: ['curtainAction']
  };

  /**
   * Stores instances of NavigationDropdown components.
   * @private
   */
  private dropdowns = [];

  /**
   * Initializes the NavigationDesktop component by creating and mounting NavigationDropdown instances.
   * It dynamically creates dropdown components based on the current HTML structure.
   * @this {NavigationDesktop & NavigationDesktopProps}
   * @public
   */
  public mounted(): void {
    const dropdown = new NavigationDropdown(this.$el);
    dropdown.$mount();

    if (!dropdown.$options.empty) {
      this.dropdowns.push(dropdown);
    }
  }

  /**
   * Cleans up the component by destroying all instances of NavigationDropdown.
   * Ensures a clean shutdown of the component and its children when no longer needed.
   * @this {NavigationDesktop & NavigationDesktopProps}
   * @public
   */
  public destroyed(): void {
    this.dropdowns.forEach((dropdown) => {
      dropdown.destroyed();
    });
    this.dropdowns = [];
  }
}

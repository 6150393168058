export {clipRect} from './clipRect';
export * from "./queryAll";
export * from "./queryOne";
export * from "./siblings";
export * from "./getParents";

/**
 * The app name you are developing.
 * @type {string}
 */
export const APP_NAME = 'tgkonz';

/**
 * Your data api key, useful for debugging for example.
 * @type {string}
 */
export const DATA_API_KEY = '.data-tgkonz';

/**
 * Constant to query the html element of the current DOM.
 * @type {HTMLElement}
 */
export const html: HTMLElement = document.documentElement;

/**
 * Constant to query the body element of the current DOM.
 * @type {HTMLBodyElement}
 */
export const body = document.body as HTMLBodyElement;

/**
 * Constant to query the backdrop element of the current DOM.
 * @type {HTMLElement}
 */
export const backdrop = document.getElementById('backdrop') as HTMLElement;

/**
 * Constant to query the static header in the current DOM.
 * @type {HTMLElement}
 */
export const header = document.getElementById('master') as HTMLElement;

/**
 * Constant to query the static footer in the current DOM.
 * @type {HTMLElement}
 */
export const footer = document.getElementById('footer') as HTMLElement;

import { Base, BaseProps } from '@studiometa/js-toolkit';
import { gsap } from 'gsap';

/**
 * Defines the properties interface for Curtain component.
 */
interface CurtainProps extends BaseProps {
  $options: {
    animation: {
      duration: Number
      autoAlpha: Number
      zIndex: Number
      ease: String
    }
  }
}

/**
 * Class representing a curtain component, used for overlay effects.
 * @extends {Base<CurtainProps>}
 */
export class Curtain extends Base<CurtainProps> {
  /**
   * Component config.
   */
  static config = {
    name: 'Curtain',
    options: {
      animation: {
        type: Object,
        default: () => ({
          duration: 0.35,
          autoAlpha: 0.6,
          zIndex: 39,
          ease: 'none',
        }),
      },
    },
  };

  /**
   * Indicates if the curtain is currently active.
   * @private
   */
  private isActive: boolean = false;

  /**
   * Sets up the component by adding an event listener to handle 'curtainAction' events.
   * * See {@link eventHandler} for the event handling implementation.
   */
  public mounted(): void {
    document.addEventListener('curtainAction', this.eventHandler);
  }

  /**
   * Event handler for 'curtainAction' events. Delegates to {@link handleCurtainAction}.
   * @this {Curtain & CurtainProps}
   * @param {CustomEvent} event - The custom event containing curtain action details.
   */
  private eventHandler = (event: CustomEvent) => {
    this.handleCurtainAction(event.detail);
  };

  /**
   * Processes the curtain action based on the provided detail.
   * Delegates actions to {@link show} and {@link hide} methods.
   * @this {Curtain & CurtainProps}
   * @param {Object} detail - Details of the curtain action.
   */
  private handleCurtainAction = (detail) => {
    const animationOptions = detail.animation || {};
    if (detail.action === 'show') {
      this.show(animationOptions);
    } else if (detail.action === 'hide') {
      this.hide(animationOptions);
    } else if (detail.action === 'clear') {
      this.clearProperties();
    }
  }

  /**
   * Animates the curtain to a visible state based on provided animation options.
   * Invoked by {@link handleCurtainAction} when the action is 'show'.
   * @this {Curtain & CurtainProps}
   * @param {Object} animationOptions - Options to customize the show animation.
   */
  public show(animationOptions = {}): void {
    if (this.isActive) return;
    const options: gsap.TweenVars = {
      duration: this.$options.animation.duration as number,
      autoAlpha: this.$options.animation.autoAlpha as number,
      zIndex: this.$options.animation.zIndex as number,
      ease: this.$options.animation.ease as string,
      ...animationOptions
    };
    gsap.to(this.$el, options);
    this.isActive = true;
  }

  /**
   * Animates the curtain to a hidden state based on provided animation options.
   * Invoked by {@link handleCurtainAction} when the action is 'hide'.
   * @this {Curtain & CurtainProps}
   * @param {Object} animationOptions - Options to customize the hide animation.
   */
  public hide(animationOptions = {}): void {
    if (!this.isActive) return;
    const options: gsap.TweenVars = {
      duration: this.$options.animation.duration as number,
      autoAlpha: 0,
      ease: this.$options.animation.ease as string,
      ...animationOptions
    };
    gsap.to(this.$el, options);
    this.isActive = false;
  }

  /**
   * Clears all GSAP properties applied to the curtain element.
   * It's useful for ensuring that all GSAP applied styles are removed,
   * bringing the element back to its original state.
   * @this {Curtain & CurtainProps}
   */
  private clearProperties = (): void => {
    gsap.set(this.$el, { clearProps: 'all' });
  };

  /**
   * Cleanup method called when the component is destroyed.
   * Removes event listeners and resets GSAP properties.
   * Counterpart to {@link mounted}.
   * @this {Curtain & CurtainProps}
   */
  public destroyed(): void {
    document.removeEventListener('curtainAction', this.eventHandler);

    if (this.isActive) {
      gsap.set(this.$el, { autoAlpha: 0 });
      gsap.set(this.$el, { clearProps: 'all' });
      this.isActive = false;
    } else {
      gsap.set(this.$el, { clearProps: 'all' });
    }
  }
}

import {Base, withBreakpointManager} from '@studiometa/js-toolkit';
import type {BaseProps} from '@studiometa/js-toolkit';
import {NavigationContextualMobile} from './NavigationContextualMobile';
import {NavigationContextualDesktop} from './NavigationContextualDesktop';

type NavigationProps = BaseProps;

/**
 * @class NavigationContextual
 * @classdesc Manages the overall navigation logic, switching between mobile and desktop navigation based on breakpoints.
 * @extends {withBreakpointManager(Base, [
 *   ['sm md', NavigationContextualMobile],
 *   ['lg xl nav 2xl mega', NavigationContextualDesktop],
 * ])<NavigationProps>}
 */
export default class NavigationContextual extends withBreakpointManager(Base, [
  ['sm md', NavigationContextualMobile],
  ['lg xl nav 2xl mega', NavigationContextualDesktop],
])<NavigationProps> {
  static config = {
    name: 'NavigationContextual',
  };
}

// eslint-disable-next-line import/no-unresolved
import '@css/app.pcss';
import {Base, createApp} from '@studiometa/js-toolkit';
import type {BaseConfig, BaseProps} from '@studiometa/js-toolkit';
import {Curtain} from '../../templates/_ui/atoms';
import {AnimatedButton} from '../../templates/_ui/atoms';
import {Navigation} from '../../templates/_ui/organisms';
import {FigureImage} from '../../templates/_ui/molecules';
import {Hero} from '../../templates/_ui/molecules';
import type NavigationContextual from "../../templates/_ui/molecules/navigation/NavigationContextual.js";
import type Breadcrumbs from "../../templates/_ui/molecules/breadcrumbs/Breadcrumbs";
import type LargeText from '../../templates/_ui/atoms/largeText/LargeText.js';
import type AnimatedLayer from "../../templates/_ui/atoms/animatedLayer/AnimatedLayer.js";
import type Cap from "../../templates/_ui/molecules/cap/Cap.js";
import type Podcast from "../../templates/_ui/atoms/podcast/Podcast";
import type Gallery from "../../templates/_ui/molecules/gallery/Gallery.js";

export interface AppProps extends BaseProps {
  $children: {
    Curtain: Curtain[];
    AnimatedButton: AnimatedButton[];
    Navigation: Navigation[];
    FigureImage: FigureImage[];
    Hero: Hero[];
    Breadcrumbs: Promise<Breadcrumbs>[];
    NavigationContextual: Promise<NavigationContextual>[];
    LargeText: Promise<LargeText>[];
    Layer: Promise<AnimatedLayer>[];
    Cap: Promise<Cap>[];
    Podcast: Promise<Podcast>[];
    Gallery: Promise<Gallery>[];
  };
}

/**
 * App class.
 */
class App extends  Base<AppProps> {
  static config: BaseConfig = {
    name: 'App',
    emits: ['curtainAction'],
    components: {
      Curtain,
      AnimatedButton,
      Navigation,
      FigureImage,
      Hero,
      NavigationContextual: () => import('../../templates/_ui/molecules/navigation/NavigationContextual.js'),
      Breadcrumbs: () => import('../../templates/_ui/molecules/breadcrumbs/Breadcrumbs.js'),
      LargeText: () => import('../../templates/_ui/atoms/largeText/LargeText.js'),
      AnimatedLayer: () => import('../../templates/_ui/atoms/animatedLayer/AnimatedLayer'),
      Cap: () => import('../../templates/_ui/molecules/cap/Cap.js'),
      Podcast: () => import('../../templates/_ui/atoms/podcast/Podcast.js'),
      Gallery: () => import('../../templates/_ui/molecules/gallery/Gallery.js'),
    },
  };
}
export default createApp(App, {
  root: document.body,
  features: {
    asyncChildren: true
  }
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(() => {
    console.log('HMR');
  });
}
